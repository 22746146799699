import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import moodboardMp4 from "../images/moodboard.mp4"
import appStoreBadge from "../images/download-app.svg"
import moodboardPoster from "../images/moodboard-preview.png"

import "./index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Moodboard - Photo collage & layout app" />
    <div className="container">
      <video className="video" poster={moodboardPoster} loop autoPlay muted playsInline>
        <source src={moodboardMp4} type="video/mp4" />
      </video>
      <div className="content">
        <div className="copy">
        <h1 className="heading">Create beautiful collages out of your photos</h1>
          <a target="_blank" href="https://apps.apple.com/us/app/moodboard-photo-collage-tool/id1465390447">
            <img src={appStoreBadge} />
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
